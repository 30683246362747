import React from "react"
import { graphql } from "gatsby"
import Layout from "@layouts/layout"
import CardsContainer from "@global/cards/CardsContainer"
import Seo from "@elements/Seo"
import { transformVlog } from "@helpers/favouritesHelper"
import Block from "@global/layout/Block"
import Banner from "@global/layout/Banner"
import Card from "@global/cards/Card"
import Content from "@elements/Content"

const EatGreatEpisodes = ({ data, pageContext: { title, parent } }) => {
  const { nodes } = data.eatGreat
  const { eatGreat } = data.content
  const { eatGreatBanner } = data.banner

  return (
    <Layout parent={parent} title={title}>
      <Seo
        title={data.content.eatGreat.meta.title}
        description={data.content.eatGreat.meta.desc}
        image={eatGreatBanner.url}
      />
      <Banner image={eatGreatBanner} />
      <Block padding="double-top" gutters={true} maxWidth="inner">
        <Content
          html={`<div><h1>${data.miscContent.eatGreatHeading}</h1>${data.miscContent.eatGreatText}</div>`}
        />
      </Block>
      <Block gutters={true} padding="both" maxWidth="list-inner">
        <CardsContainer>
          {nodes.map(node => {
            return (
              <Card
                key={`eat-great-card-${node.id}`}
                {...transformVlog(node)}
                modifier="horizontal-for-small"
              />
            )
          })}
        </CardsContainer>
      </Block>
    </Layout>
  )
}

export const query = graphql`
  query EatGreatQuery {
    eatGreat: allDatoCmsEatGreat(
      sort: { fields: meta___firstPublishedAt, order: DESC }
    ) {
      nodes {
        title
        slug
        id
        path
        description
        chosenThumbnail {
          gatsbyImageData(imgixParams: { fit: "crop", w: "740", h: "532" })
        }
        meta {
          publishedAt
        }
      }
    }

    banner: datoCmsGlobal {
      eatGreatBanner {
        gatsbyImageData(
          imgixParams: { w: "1280", h: "640", fit: "crop", crop: "faces" }
        )
        url(imgixParams: { w: "1000", fit: "max", fm: "jpg" })
      }
    }

    content: contentYaml(language: { eq: "en-NZ" }) {
      eatGreat {
        content
        meta {
          title
          desc
        }
      }
    }
    seo: datoCmsSite {
      ...GlobalSeo
    }
    miscContent: datoCmsMiscContent {
      tipsIntroText
      eatGreatHeading
      eatGreatText
    }
  }
`

export default EatGreatEpisodes
